import { FunctionComponent, InputHTMLAttributes, ReactElement } from "react";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input/input";

import { loadDefaultCountry } from "@smart/itops-locale-dom";

import { ErrorDisplay } from "./error";
import { TextInput } from "./input";
import { Label } from "./label";
import { FieldProps, FieldWrapper } from "./wrapper";
import { fieldName } from "../../hooks";

type RemoveFunctions<T> = {
  [K in keyof T as T[K] extends Function ? never : K]: T[K];
};

/**
 * PhoneInput's inputComponent prop requires a react component that always return something.
 * This extends react's FunctionComponent type meet the requirement.
 */
interface InputComponent extends RemoveFunctions<FunctionComponent> {
  (
    props: InputHTMLAttributes<HTMLInputElement>,
    context?: any,
  ): ReactElement<any, any>;
}

export const PhoneNumberField = ({
  field,
  index,
  innerRef,
  value,
  error,
  loading,
  disabled,
  onChange,
  onBlur,
}: FieldProps<HTMLInputElement>) => {
  const defaultCountry = loadDefaultCountry();
  const id = fieldName({ field, index });
  const errorId = fieldName({ field, index, suffix: "error" });

  return (
    <FieldWrapper aria-disabled={disabled} isLoading={loading}>
      <Label {...field} index={index} />
      <PhoneInput
        inputComponent={TextInput as InputComponent}
        defaultCountry={defaultCountry}
        value={value?.number ?? ""}
        ref={innerRef}
        id={id}
        name={id}
        aria-invalid={!!error}
        aria-errormessage={error ? errorId : undefined}
        onChange={(v) => {
          const parsed = parsePhoneNumber(v ?? "", defaultCountry);
          onChange(
            parsed || v
              ? {
                  countryCallingCode: parsed?.countryCallingCode || null,
                  country: parsed?.country || null,
                  number: parsed?.number || v || null,
                  formattedNumber: parsed?.formatNational() || v || null,
                }
              : null,
          );
        }}
        onBlur={onBlur}
      />
      <ErrorDisplay uri={field.uri} index={index} error={error} />
    </FieldWrapper>
  );
};
