import styled from "@emotion/styled";
import { MouseEvent, RefObject, ReactNode } from "react";

import { Toggle as ToggleComponent } from "@smart/itops-components-dom/src/toggle";

import { ClearFormModal } from "./clear-form";
import {
  Page,
  Section,
  SectionButtons,
  SectionHeading,
  SectionTeam,
  PageWrapper,
  SectionForm,
  SectionGap,
} from "./section-components";
import { ConnectionItem, SectionErrors, SectionItem, TeamItem } from "../types";

const ToggleErrorsWrapper = styled.div`
  margin: 1rem auto -2rem;
  display: flex;
  justify-content: flex-end;
  width: ${(props) => props.theme.breakPoints.readable}px;
  max-width: 94%;
  max-height: 2rem;
  .toggle-errors {
    h4 {
      font-size: small;
    }
  }
`;

const Toggle = styled(ToggleComponent)`
  .toggle-label {
    .toggle-label-text {
      font-size: ${(props) => props.theme.fontSize.base};
      font-weight: 400;
      margin-right: 0.5rem;
    }
  }
`;

export type IntakeSectionProps = {
  team: TeamItem;
  hideTeam?: boolean;
  connection?: ConnectionItem;
  sections: SectionItem[];
  selected: SectionItem;
  sidebar: boolean;
  onPreviousButton?: () => void;
  onNextButton?: (e: MouseEvent<HTMLButtonElement>) => void;
  onSubmitButton?: (e: MouseEvent<HTMLButtonElement>) => void;
  onSubmit?: () => void;
  onReset?: () => void;
  children?: ReactNode;
  errors?: SectionErrors;
  errorsRef?: RefObject<HTMLDivElement>;
  submitError?: string;
  isForm?: boolean;
  previewOptions?: {
    previewErrorToggle: [
      boolean,
      React.Dispatch<React.SetStateAction<boolean>>,
    ];
    clearPreviewResponses: () => void;
    clearFormModal: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  };
  isInternal?: boolean;
};

const noop = () => {};

export const IntakeSection = ({
  team,
  hideTeam,
  connection,
  sections,
  selected,
  sidebar,
  onPreviousButton,
  onNextButton,
  onSubmitButton,
  onSubmit,
  onReset,
  children,
  submitError,
  errors,
  errorsRef,
  isForm = true,
  previewOptions,
  isInternal,
}: IntakeSectionProps) => {
  const selectedIndex = selected
    ? sections.findIndex((s) => s.uri === selected?.uri)
    : -1;

  const buttons = {
    onPrevious: selectedIndex > 0 ? onPreviousButton || noop : undefined,
    onNext: sections.length > 1 ? onNextButton || noop : undefined,
    onSubmit: sections.length === 1 ? onSubmitButton || noop : undefined,
  };

  return (
    <PageWrapper sidebar={sidebar}>
      {previewOptions && (
        <ToggleErrorsWrapper>
          <Toggle
            text="Field validation"
            toggled={!previewOptions.previewErrorToggle[0]}
            onClick={() =>
              previewOptions.previewErrorToggle[1](
                !previewOptions.previewErrorToggle[0],
              )
            }
            size={3}
          />
        </ToggleErrorsWrapper>
      )}
      <Page>
        {!hideTeam && (
          <SectionTeam
            team={team}
            connection={connection}
            isInternal={isInternal}
          />
        )}
        <Section>
          <SectionForm onSubmit={onSubmit} onReset={onReset} isForm={isForm}>
            <SectionHeading
              section={selected}
              errors={errors}
              errorsRef={errorsRef}
              hideErrors={previewOptions?.previewErrorToggle[0]}
              connection={connection}
            />
            <SectionGap />
            {children}
            <SectionButtons
              isForm={isForm}
              team={team}
              hasError={submitError}
              clearFormModal={() => previewOptions?.clearFormModal[1](true)}
              displayClearFormButton={!!previewOptions?.clearFormModal}
              {...buttons}
            />
          </SectionForm>
        </Section>
      </Page>
      {previewOptions && (
        <ClearFormModal
          clearFormModal={previewOptions.clearFormModal}
          update={previewOptions.clearPreviewResponses}
        />
      )}
    </PageWrapper>
  );
};
