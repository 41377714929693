import styled from "@emotion/styled";
import { addDays, addMinutes, isAfter, isSameDay, isToday } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";
import { useCallback } from "react";

import {
  WeekDay,
  calendarWeekDays,
  calendarWeekShortLabel,
} from "../../../../types";

const CalendarDays = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 1%;
`;

const WeekDayHeader = styled.div`
  width: 14%;
  height: 3rem;
  margin-bottom: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${(props) => props.theme.fontSize.base};
`;

const Day = styled.div<{
  isCurrentMonth?: boolean;
  isSelected?: boolean;
  isToday?: boolean;
  disabled?: boolean;
}>`
  width: 14%;
  height: 3.6rem;

  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.isCurrentMonth ? "1" : "0")};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => (props.isToday ? "600" : "inherit")};
  color: ${(props) =>
    props.disabled && !props.isToday
      ? props.theme.palette.disabled.base
      : props.theme.palette.foreground.base};
  text-decoration: ${(props) => (props.isToday ? "underline" : "none")};
  text-underline-offset: 0.5rem;
  text-decoration-thickness: 2px;

  cursor: ${(props) =>
    props.disabled || !props.isCurrentMonth ? "default" : "pointer"};

  .day-container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 3rem;
    height: 3rem;
    border-radius: 0.6rem;
    border: 1px solid;
    border-color: ${(props) =>
      props.isSelected ? props.theme.palette.active.accent : "transparent"};

    background: ${(props) =>
      props.isSelected ? props.theme.palette.active.highlight : "transparent"};

    &:hover,
    &:focus {
      border-color: ${(props) => props.theme.palette.active.accent};
      background: ${(props) =>
        props.disabled
          ? "transparent"
          : props.theme.palette.background.highlight};
    }
  }
`;

type DaysPickerProps = {
  selectedDate: Date | undefined | null;
  currentDate: Date;
  laterThan?: Date;
  earlierThan?: Date;
  excludingWeekDays?: WeekDay[];
  excludingDates?: Date[];
  disabled?: boolean;
  timezone?: string | null;
  onChage: (date: Date) => void;
};

export const DaysPicker = ({
  selectedDate,
  currentDate,
  earlierThan,
  laterThan,
  excludingWeekDays,
  excludingDates,
  disabled,
  timezone,
  onChage,
}: DaysPickerProps) => {
  const renderDays = useCallback(() => {
    const today = new Date();
    const days = [];
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const startOfMonth = new Date(year, month, 1);
    const endOfMonth = new Date(year, month + 1, 0);
    const startOfWeek = (startOfMonth.getDay() + 6) % 7;
    const endOfWeek = (endOfMonth.getDay() + 6) % 7;
    const daysInMonth = endOfMonth.getDate();

    for (let day = 0; day < startOfWeek; day += 1) {
      days.push(
        <Day
          key={`prev-month-${day}`}
          isCurrentMonth={false}
          isSelected={false}
        />,
      );
    }

    for (let day = 1; day <= daysInMonth; day += 1) {
      const localDate = new Date(year, month, day);
      const offsetInMinute = timezone
        ? getTimezoneOffset(timezone, localDate) / (60 * 1000)
        : 0;
      const date = addMinutes(localDate, offsetInMinute);

      const canSelect =
        isAfter(date, laterThan || addDays(today, -1)) &&
        (!earlierThan || isAfter(earlierThan, date));
      const excluding =
        excludingWeekDays?.includes(date.getDay()) ||
        excludingDates?.some((d) => isSameDay(d, date));
      const isDisabled = disabled || !canSelect || excluding;

      days.push(
        <Day
          key={`current-month-${day}`}
          isCurrentMonth
          isSelected={!!selectedDate && isSameDay(date, selectedDate)}
          isToday={isToday(date)}
          disabled={isDisabled}
          onClick={() => !isDisabled && onChage(date)}
        >
          <div className="day-container">{day}</div>
        </Day>,
      );
    }

    for (let day = endOfWeek + 1; day < 7; day += 1) {
      days.push(
        <Day
          key={`next-month-${day}`}
          isCurrentMonth={false}
          isSelected={false}
        />,
      );
    }

    return days;
  }, [currentDate, selectedDate, disabled]);

  return (
    <CalendarDays>
      {calendarWeekDays.map((wd) => (
        <WeekDayHeader key={wd}>{calendarWeekShortLabel[wd]}</WeekDayHeader>
      ))}
      {renderDays()}
    </CalendarDays>
  );
};
