import { FloatingFocusManager, useMergeRefs } from "@floating-ui/react";
import { useRef, useState } from "react";

import { google } from "@smart/bridge-images-dom";
import { AddressPrediction } from "@smart/bridge-resources-basic";
import { Spinner } from "@smart/itops-components-dom";
import { useDebouncedValue } from "@smart/itops-hooks-dom";
import { specialChars } from "@smart/itops-utils-basic";

import { ErrorDisplay } from "./error";
import { InputButton, InputWrapper } from "./input";
import { Label } from "./label";
import { Item, ItemList, useFloatingList } from "./list-field";
import { FieldProps, FieldWrapper } from "./wrapper";
import { fieldName } from "../../hooks";
import { LookupOptions } from "../../types";

export const AddressField = ({
  field,
  index,
  innerRef,
  value,
  error,
  loading: fieldLoading,
  disabled,
  onChange,
  onBlur,
  addressLookup: { search, load },
}: FieldProps<HTMLInputElement> & {
  addressLookup: LookupOptions["addressLookup"];
}) => {
  const fieldRef = useRef<HTMLInputElement>(null);
  const sessionKey = useRef<string>();
  const [fetching, setFetching] = useState(false);
  const searchValue = useDebouncedValue(value?.formattedAddress ?? "", 500);
  const { result, loading } = search({
    sessionKey: sessionKey.current,
    search: searchValue,
  });
  const id = fieldName({ field, index });
  const errorId = fieldName({ field, index, suffix: "error" });
  const mergedRef = useMergeRefs([innerRef, fieldRef]);

  const {
    onOpenChange,
    refs,
    listRef,
    floatingStyles,
    getFloatingProps,
    getItemProps,
    getReferenceProps,
    activeIndex,
    setActiveIndex,
    open,
    context,
  } = useFloatingList();

  const onSelect = async (item: AddressPrediction) => {
    onOpenChange(false);
    onChange({ formattedAddress: item.description });
    setFetching(true);
    const place = await load({
      placeId: item.place_id,
      sessionKey: sessionKey.current,
    });
    onChange(place ?? null);
    sessionKey.current = undefined;
    setFetching(false);
    onBlur();
  };

  return (
    <FieldWrapper aria-disabled={disabled} isLoading={fieldLoading}>
      <Label {...field} index={index} />
      <InputWrapper
        ref={refs.setReference}
        aria-invalid={!!error}
        aria-errormessage={error ? errorId : undefined}
      >
        <input
          ref={mergedRef}
          {...getReferenceProps({
            onKeyDown: async (e) => {
              if (
                e.key === "Enter" &&
                activeIndex != null &&
                result?.[activeIndex]
              ) {
                await onSelect(result[activeIndex]);
              }
            },
          })}
          id={id}
          name={id}
          aria-invalid={!!error}
          aria-errormessage={error ? errorId : undefined}
          aria-autocomplete="list"
          value={value?.formattedAddress ?? ""}
          onChange={(e) => {
            if (!sessionKey.current)
              sessionKey.current = `${field.uri}-${Date.now()}`;
            onChange({ formattedAddress: e.target.value });
            onOpenChange(true);
            setActiveIndex(0);
          }}
          autoComplete="off"
          disabled={fetching}
        />
        {!!value && (
          <InputButton
            name="cancelCircle"
            title="Clear"
            palette="foreground"
            disabled={fetching}
            onClick={() => {
              onChange(null);
              onOpenChange(false);
              onBlur();
              fieldRef?.current?.focus();
            }}
          />
        )}
      </InputWrapper>
      {open && (
        <FloatingFocusManager
          context={context}
          initialFocus={-1}
          visuallyHiddenDismiss
        >
          <ItemList
            {...getFloatingProps({
              ref: refs.setFloating,
              style: floatingStyles,
            })}
          >
            {result?.map((item, idx) => (
              <Item
                key={item.place_id}
                id={`${id}-${item.place_id}`}
                {...getItemProps({
                  ref(node) {
                    listRef.current[idx] = node;
                  },
                })}
                onClick={async () => {
                  await onSelect(item);
                }}
                type="button"
                role="option"
                aria-current={activeIndex === idx}
              >
                <strong>{item.structured_formatting.main_text}</strong>
                <span>
                  {specialChars.nbsp}
                  {item.structured_formatting.secondary_text}
                </span>
              </Item>
            ))}
            <div className="footer">
              {loading && <Spinner size={2} />}
              <img src={google} alt="Powered by Google" className="provider" />
            </div>
          </ItemList>
        </FloatingFocusManager>
      )}
      <ErrorDisplay uri={field.uri} index={index} error={error} />
    </FieldWrapper>
  );
};
