import { ReactNode } from "react";

import {
  ScrollWrapper,
  SectionSidebar,
  SectionSidebarProps,
} from "./section-components";

export const IntakeSidebar = ({
  children,
  newUI,
  ...props
}: SectionSidebarProps & {
  hideSidebar?: boolean;
  children?: ReactNode;
  newUI?: boolean;
}) => (
  <ScrollWrapper newUI={newUI}>
    {children}
    {props.visibleSections.length > 1 && <SectionSidebar {...props} />}
  </ScrollWrapper>
);
