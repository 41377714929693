import styled from "@emotion/styled";
import { ReactNode } from "react";

import { Displayer } from "@smart/itops-editor-dom";

import { GroupItem } from "../../types";

const SummaryGroupWrapper = styled.div`
  margin: 1rem 0;
  border-left: 3px solid ${(props) => props.theme.palette.background.highlight};
  padding-left: ${(props) => props.theme.baseUnit}rem;

  h5 {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 400;
    margin: ${(props) => props.theme.baseUnit * 0.6}rem 0;
    text-decoration: underline;
  }
`;

export type SummaryGroupProps = {
  group: GroupItem;
  index: number;
  children?: ReactNode;
};

export const SummaryGroup = ({
  group: { label, repeatable },
  index,
  children,
}: SummaryGroupProps) => (
  <SummaryGroupWrapper>
    <Displayer
      paragraphTag="h5"
      value={label}
      hintElement={repeatable ? <span> {index + 1}</span> : undefined}
    />
    {children}
  </SummaryGroupWrapper>
);
