import { useRef } from "react";

import { StaffDetails } from "@smart/bridge-types-basic/src/appointment";
import { mapEntries } from "@smart/itops-utils-basic";

import { IntakeFormSection, IntakeFormSummary } from "./form-components";
import { IntakeSidebar } from "./sidebar";
import { IntakeStatus } from "./status";
import { IntakeFormHookProps, useIntakeForm } from "../hooks";
import {
  IntakeFormSectionRef,
  LookupOptions,
  SectionItem,
  TeamItem,
} from "../types";

export type IntakeFormProps = IntakeFormHookProps & {
  form: {
    team: TeamItem;
    hideTeam?: boolean;
    response?: string;
    category?: string;
  };
  lookup: LookupOptions;
  previewOptions?: {
    previewErrorToggle: [
      boolean,
      React.Dispatch<React.SetStateAction<boolean>>,
    ];
    previewResponses: [
      Record<string, any>,
      React.Dispatch<React.SetStateAction<Record<string, any>>>,
    ];
    clearFormModal: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    previewLastSection: [
      SectionItem | "summary" | undefined,
      React.Dispatch<React.SetStateAction<SectionItem | "summary" | undefined>>,
    ];
  };
  isInternal?: boolean;
  newUI?: boolean;
  staffDetails: StaffDetails[];
};

export const IntakeForm = ({
  form,
  lookup,
  previewOptions,
  isInternal,
  newUI,
  staffDetails,
  ...formOptions
}: IntakeFormProps) => {
  const {
    summary,
    statusProps,
    sidebarProps,
    sectionProps,
    summaryProps,
    printProps,
    uploadFileProps,
  } = useIntakeForm({
    ...formOptions,
    previewLastSection: previewOptions?.previewLastSection,
    submission: {
      ...formOptions.submission,
      responses:
        (previewOptions?.previewResponses[0]
          ? mapEntries<Record<string, any>, Record<string, { value: any }>>(
              previewOptions.previewResponses[0],
              (value) => ({
                value,
              }),
            )
          : undefined) || formOptions.submission.responses,
    },
  });

  const sectionRef = useRef<IntakeFormSectionRef>(null);

  const clearPreviewResponses = () => {
    previewOptions?.previewResponses[1]({});
    sectionProps.setResponses({});
    sectionProps.setStatus("active");
    previewOptions?.clearFormModal[1](false);
    sectionProps.onSelect(sectionProps.visibleSections[0]);
  };

  if ((statusProps.status !== "active" && !isInternal) || statusProps.deleted) {
    return (
      <IntakeStatus
        {...form}
        {...statusProps}
        printOptions={printProps}
        previewOptions={
          previewOptions
            ? {
                clearFormModal: previewOptions.clearFormModal,
                clearPreviewResponses,
              }
            : undefined
        }
      />
    );
  }

  return (
    <IntakeSidebar {...sidebarProps} sectionRef={sectionRef} newUI={newUI}>
      {summary ? (
        <IntakeFormSummary
          {...form}
          {...summaryProps}
          {...sectionProps}
          previewOptions={
            previewOptions
              ? {
                  previewResponses: previewOptions.previewResponses[0],
                  clearFormModal: previewOptions.clearFormModal,
                  clearPreviewResponses,
                  clearLastPreviewSection: () =>
                    previewOptions.previewLastSection[1](undefined),
                  hidePreviewErrors: previewOptions.previewErrorToggle[0],
                }
              : undefined
          }
        />
      ) : (
        <IntakeFormSection
          {...form}
          {...lookup}
          {...sectionProps}
          isInternal={isInternal}
          uploadFileProps={uploadFileProps}
          ref={sectionRef}
          submissionSyncStatus={formOptions.submission.syncStatus}
          previewOptions={
            previewOptions
              ? { ...previewOptions, clearPreviewResponses }
              : undefined
          }
          staffDetails={staffDetails}
        />
      )}
    </IntakeSidebar>
  );
};
