import styled from "@emotion/styled";
import { useState } from "react";

import { DaysPicker } from "./days-picker";
import { MonthPicker } from "./month-picker";
import { WeekDay } from "../../../../types";

const Container = styled.div`
  max-width: 48rem;

  .heading {
    margin: 0 0 0.6rem 0.6rem;
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 500;
  }
`;

type DatePickerProps = {
  heading?: string;
  selectedDate: Date | undefined | null;
  laterThan?: Date;
  earlierThan?: Date;
  excludingWeekDays?: WeekDay[];
  excludingDates?: Date[];
  disabled?: boolean;
  timezone?: string | null;
  onChange: (date: Date) => void;
};

export const DatePicker = ({
  heading,
  selectedDate,
  laterThan,
  earlierThan,
  excludingWeekDays,
  excludingDates,
  disabled,
  timezone,
  onChange,
}: DatePickerProps) => {
  const [currentDate, setCurrentDate] = useState(selectedDate || new Date());

  const onMonthChange = (date: Date) => {
    setCurrentDate(date);
  };

  const onDateChange = (date: Date) => {
    setCurrentDate(date);
    onChange(date);
  };

  return (
    <Container data-testid="intake-date-picker">
      {heading && <div className="heading">{heading}</div>}
      <MonthPicker
        currentDate={currentDate}
        onChange={onMonthChange}
        disabled={disabled}
      />
      <DaysPicker
        selectedDate={selectedDate}
        currentDate={currentDate}
        laterThan={laterThan}
        earlierThan={earlierThan}
        excludingWeekDays={excludingWeekDays}
        excludingDates={excludingDates}
        onChage={onDateChange}
        disabled={disabled}
        timezone={timezone}
      />
    </Container>
  );
};
