import styled from "@emotion/styled";

import { formatValue, UploadedFile } from "@smart/bridge-types-basic";
import { Displayer } from "@smart/itops-editor-dom";
import { localTimeZoneCode, specialChars } from "@smart/itops-utils-basic";

import { FieldComponentType, fieldFallbackLabel, FieldItem } from "../../types";
import { displayAppointmentValues } from "../../utils";
import { Indicator } from "../field-components";

const SummaryFieldWrapper = styled.div`
  margin: ${(props) => props.theme.baseUnit * 1.6}rem 0;

  h4 {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 500;
    margin: ${(props) => props.theme.baseUnit * 0.6}rem 0;
  }

  p {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 400;
    margin: ${(props) => props.theme.baseUnit * 0.6}rem 0;
  }
`;

export type SummaryFieldProps = {
  field: FieldItem;
  value: any;
};

export const SummaryField = ({
  field: { label, mandatory, type, options },
  value,
}: SummaryFieldProps) => {
  const displayFieldValue = () => {
    if (type === "info") return null;

    if (type === "file" && Array.isArray(value)) {
      return value?.length
        ? value.map((file: UploadedFile) => (
            <p key={file.downloadUrl}>{file.fileName}</p>
          ))
        : specialChars.enDash;
    }

    if (type === "appointment") {
      const date = displayAppointmentValues({
        startTime: value?.startTime,
        endTime: value?.endTime,
        timezone: value?.timezone,
      });
      return (
        <div>
          <p>
            {value?.bookedStaff?.length
              ? [
                  value.bookedStaff[0].firstName,
                  value.bookedStaff[0].middleName,
                  value.bookedStaff[0].lastName,
                ]
                  .filter(Boolean)
                  .join(" ")
              : specialChars.enDash}
          </p>
          <p>{date.day}</p>
          <p>{date.time}</p>
          <p>{value?.timezone || localTimeZoneCode}</p>
          {value?.status === "invalid" && <p>Not booked</p>}
        </div>
      );
    }
    return (
      <Displayer
        paragraphTag="p"
        value={formatValue(value, type, options)}
        fallback={specialChars.enDash}
      />
    );
  };

  return (
    <SummaryFieldWrapper>
      <Displayer
        paragraphTag="h4"
        value={label}
        fallback={fieldFallbackLabel[type as FieldComponentType]}
        hintElement={mandatory && <Indicator />}
      />
      {displayFieldValue()}
    </SummaryFieldWrapper>
  );
};
