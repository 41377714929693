import { differenceInHours } from "date-fns";
import { useState } from "react";

import { SubmissionStatus } from "@smart/bridge-types-basic";
import { entriesOf, fromEntries, mapEntries } from "@smart/itops-utils-basic";

import { useDBResponses } from "./database-responses";
import { useFileUpload } from "./file-upload";
import { useLoadMatterFields } from "./load-matter-fields";
import { useVisibility } from "./visibility";
import { useSectionVisit } from "./visit";
import {
  ConnectionItem,
  FieldItem,
  GroupItem,
  LoadResponses,
  ResponseSubmitter,
  SectionItem,
  SubmissionItem,
  SubmissionStatusItem,
} from "../types";

const LinkSaveCopyTTLInHours = 24;

export type IntakeFormHookProps = {
  submission: SubmissionItem;
  sections: SectionItem[];
  groups: GroupItem[];
  fields: FieldItem[];
  connection?: ConnectionItem;
  disabled?: boolean;
  submitResponses: ResponseSubmitter;
  loadResponses: LoadResponses;
  shouldLoadDBResponses?: boolean;
  postSubmissionStatus?: SubmissionStatusItem;
  previewLastSection?: [
    SectionItem | "summary" | undefined,
    React.Dispatch<React.SetStateAction<SectionItem | "summary" | undefined>>,
  ];
};

export const useIntakeForm = ({
  submission,
  sections,
  fields,
  groups,
  connection,
  disabled,
  submitResponses,
  loadResponses,
  shouldLoadDBResponses,
  postSubmissionStatus,
  previewLastSection,
}: IntakeFormHookProps) => {
  const [sidebar, setSidebar] = useState(false);
  const [summary, setSummary] = useState(previewLastSection?.[0] === "summary");
  const [responses, setResponses] = useState(() =>
    submission.responses
      ? mapEntries<Record<string, { value: any }>, Record<string, any>>(
          submission.responses,
          ({ value }) => value,
        )
      : {},
  );
  const [status, setStatus] = useState<SubmissionStatus | undefined>(
    submission.status || "active",
  );

  const uploadFileProps = useFileUpload();
  const visibility = useVisibility({ sections, groups, fields, responses });
  const visibleSections = sections
    .filter((s) => !!visibility.visibleSections[s.uri])
    .map((section) => ({
      ...section,
      hasRequired: !!fields.find(
        (f) => f.sectionUri === section.uri && f.mandatory,
      ),
    }));

  const [selected, setSelected] = useState<SectionItem>(
    (submission.lastUpdatedSectionUri &&
      visibleSections.find(
        (s) => s.uri === submission.lastUpdatedSectionUri,
      )) ||
      (previewLastSection?.[0] !== "summary" && previewLastSection?.[0]) ||
      visibleSections[0],
  );

  const {
    saveTimestampAndSubmit,
    databaseResponses,
    isLoading: isLoadingDBResponses,
  } = useDBResponses({
    submitResponses,
    loadResponses,
    shouldLoad: !!shouldLoadDBResponses && summary,
  });

  const {
    isUpdating,
    isAllVisited,
    isSectionVisited,
    isSectionVisiting,
    setSectionVisited,
    setSectionVisiting,
    isNavigatable,
  } = useSectionVisit({
    selected,
    visibleSections,
    visibleFields: fields.filter((f) => !!visibility.visibleFields[f.uri]),
    lastUpdatedSectionUri: submission.lastUpdatedSectionUri,
    responses,
  });

  const onSelect = (section: SectionItem) => {
    setSectionVisiting(section);
    setSummary(false);
    setSelected(section);
    if (previewLastSection) previewLastSection[1](section);
  };

  const onSummary = () => {
    setSummary(true);
    if (previewLastSection) previewLastSection[1]("summary");
  };

  let printUnavailable: string | undefined;
  if (!disabled && (status !== "completed" || !!submission.deleted)) {
    printUnavailable = "Saving a copy of the form is not available.";
  } else if (
    !!submission.updatedAt &&
    differenceInHours(new Date(), new Date(submission.updatedAt)) >
      LinkSaveCopyTTLInHours
  ) {
    printUnavailable = `Saving a copy of the form is not available after ${LinkSaveCopyTTLInHours} hours.`;
  }

  useLoadMatterFields(
    {
      submissionSyncStatus: submission.syncStatus,
      fn: () => {
        if (submission.responses) {
          setResponses((currentResponses) => {
            const loadedResponses = fromEntries(
              entriesOf(submission.responses!)
                .filter(
                  ([fieldUri, { syncStatus }]) =>
                    !currentResponses[fieldUri] && syncStatus === "loaded",
                )
                .map(([fieldUri, { value }]) => [fieldUri, value]),
            );

            return {
              ...currentResponses,
              ...loadedResponses,
            };
          });
        }
      },
    },
    [submission.responses],
  );

  return {
    status: postSubmissionStatus?.status || status,
    summary,
    statusProps: {
      status: postSubmissionStatus?.status || status,
      deleted:
        postSubmissionStatus?.deleted !== undefined
          ? postSubmissionStatus?.deleted
          : submission.deleted,
    },
    sidebarProps: {
      visibleSections,
      selected,
      onSelect,
      summary,
      onSummary,
      sidebar,
      setSidebar,
      isUpdating,
      isAllVisited,
      isNavigatable,
      isSectionVisited,
      isSectionVisiting,
    },
    summaryProps: {
      connection,
      sections,
      groups,
      fields,
      onSelect,
      submitResponses: saveTimestampAndSubmit,
      isLoadingDBResponses,
      setStatus,
      sidebar,
      responses: shouldLoadDBResponses ? databaseResponses : responses,
      visibility,
      disabled,
    },
    sectionProps: {
      key: selected?.uri,
      connection,
      visibleSections,
      isUpdating,
      selected,
      groups,
      fields,
      onSelect,
      onSummary,
      submitResponses: saveTimestampAndSubmit,
      setResponses,
      setStatus,
      setVisited: setSectionVisited,
      sidebar,
      responses,
      visibility,
      disabled,
    },
    printProps: {
      content: {
        sections,
        groups,
        fields,
        visibility,
        responses,
      },
      disabled,
      unavailable: printUnavailable,
    },
    uploadFileProps,
  };
};
