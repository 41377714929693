import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { useState } from "react";

import { IconButton, Spinner } from "@smart/itops-components-dom";
import { IconName } from "@smart/itops-icons-dom";
import { Modal } from "@smart/itops-smokeball-components-dom";
import { FailureMessage } from "@smart/itops-ui-dom";

import { ConnectionItem, ConnectionStatus, TeamItem } from "../../types";

const SectionTeamWrapper = styled.header`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  background-color: ${(props) => props.theme.palette.background.accent};
  border-radius: 4px 4px 0 0;
  padding: ${(props) => props.theme.baseUnit}rem;
  min-height: ${(props) => props.theme.baseUnit * 8}rem;

  .team-logo {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    width: ${(props) => props.theme.baseUnit * 6}rem;
    height: ${(props) => props.theme.baseUnit * 6}rem;
  }

  h3 {
    flex: 1;
    font-size: ${(props) => props.theme.fontSize.emphasis};
    font-weight: 500;
    margin: 0 ${(props) => props.theme.baseUnit}rem;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const statusPalette: Record<ConnectionStatus, keyof Theme["palette"]> = {
  initial: "foreground",
  loading: "foreground",
  success: "foreground",
  error: "danger",
};

const statusIcon: Record<ConnectionStatus, IconName> = {
  initial: "upload",
  loading: "upload",
  success: "upload",
  error: "uploadFailed",
};

const statusText: Record<ConnectionStatus, string> = {
  initial: "",
  loading: "Saving...",
  success: "Changes saved",
  error: "Error while saving",
};

export type SectionTeamProps = {
  team: TeamItem;
  connection?: ConnectionItem;
  isInternal?: boolean;
};

export const SectionTeam = ({
  team: { name, email, picture },
  connection,
  isInternal,
}: SectionTeamProps) => {
  const [statusModal, setStatusModal] = useState(false);

  return (
    <>
      <SectionTeamWrapper>
        {picture && (
          <div
            role="img"
            aria-label={`${name} Logo`}
            className="team-logo"
            style={{ backgroundImage: `url(${picture})` }}
          />
        )}
        <h3>{name}</h3>
        {connection && (
          <IconButton
            transparent
            onClick={() => setStatusModal(true)}
            icon={
              connection.status === "loading" ? <Spinner size={2} /> : undefined
            }
            name={statusIcon[connection.status]}
            text={statusText[connection.status]}
            palette={statusPalette[connection.status]}
            data-testid="team-heading-status"
          />
        )}
      </SectionTeamWrapper>
      <Modal
        title=" "
        subtitleIcon="information"
        subtitle="Changes saved"
        open={[statusModal, setStatusModal]}
        buttons={[{ text: "Close", type: "reset" }]}
      >
        <p>
          Your answers are automatically saved as you work. Please contact us if
          you experience any issues.
        </p>
        {connection?.status === "error" && (
          <FailureMessage
            action="save changes"
            textOverride="The changes could not be saved."
            contactLink={(email && `mailto:${email}`) || undefined}
            contactName={isInternal ? "support" : name || "us"}
          />
        )}
      </Modal>
    </>
  );
};
