import { ReactNode } from "react";

import { FieldItem, GroupItem, OrderedItem } from "../types";

export const groupLength = ({
  group,
  fields,
  responses,
}: {
  group: GroupItem;
  fields: FieldItem[];
  responses: Record<string, any>;
}) => {
  if (!group.repeatable) return 1;

  return Math.max(
    ...fields.map((f) =>
      Array.isArray(responses[f.uri]) ? responses[f.uri].length : 1,
    ),
  );
};

export type RenderOrderedItemsOptions = {
  orderedItems: OrderedItem[];
  responses: Record<string, any>;
  renderGroup: (props: {
    key: string;
    group: GroupItem;
    fields: FieldItem[];
    length: number;
    index: number;
    children: ReactNode;
  }) => JSX.Element;
  renderField: (props: {
    key: string;
    index?: number;
    field: FieldItem;
    value: any;
  }) => JSX.Element | null;
};

export const renderOrderedItems = ({
  orderedItems,
  responses,
  renderGroup,
  renderField,
}: RenderOrderedItemsOptions) =>
  orderedItems.flatMap((item) => {
    if (item.type === "field")
      return renderField({
        key: item.field.uri,
        field: item.field,
        value: responses[item.field.uri],
      });

    const length = groupLength({ ...item, responses });
    return Array.from({ length }, (_, index) =>
      renderGroup({
        index,
        length,
        key: `${item.group.uri}-${length}-${index}`,
        group: item.group,
        fields: item.fields,
        children: item.fields.map((field) =>
          renderField({
            key: field.uri,
            index,
            field,
            value: responses[field.uri]?.[index],
          }),
        ),
      }),
    );
  });
