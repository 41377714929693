import styled from "@emotion/styled";
import { ReactNode } from "react";

import { Displayer } from "@smart/itops-editor-dom";
import { ParagraphTag } from "@smart/itops-serialisation-basic";

import { sectionFallback, SectionItem } from "../../types";

const SummarySectionWrapper = styled.section`
  border-bottom: 1px solid
    ${(props) => props.theme.palette.background.highlight};
  padding-bottom: ${(props) => props.theme.baseUnit}rem;
  margin-bottom: ${(props) => props.theme.baseUnit}rem;

  .title {
    display: flex;
    flex-flow: row;
    align-items: center;

    h2 {
      font-size: ${(props) => props.theme.fontSize.emphasis};
      font-weight: 400;
      text-decoration: underline;
      margin: ${(props) => props.theme.baseUnit}rem 0;
    }

    button {
      background: none;
      border: 0;
      cursor: pointer;
      color: ${(props) => props.theme.palette.primary.base};
      font-size: ${(props) => props.theme.fontSize.base};
      text-decoration: underline;
      margin: ${(props) => props.theme.baseUnit}rem 0
        ${(props) => props.theme.baseUnit}rem auto;
    }
  }
`;

export type SummarySectionProps = {
  section: SectionItem;
  onEdit?: () => void;
  children?: ReactNode;
  titleParagraphTag?: ParagraphTag;
};

export const SummarySection = ({
  section,
  onEdit,
  children,
  titleParagraphTag = "h2",
}: SummarySectionProps) => (
  <SummarySectionWrapper>
    <div className="title">
      <Displayer
        paragraphTag={titleParagraphTag}
        value={section.title}
        fallback={sectionFallback}
      />
      {onEdit && (
        <button type="button" onClick={onEdit}>
          Edit
        </button>
      )}
    </div>
    {children}
  </SummarySectionWrapper>
);
