import { Modal } from "@smart/itops-smokeball-components-dom";

export type ClearFormModalProps = {
  clearFormModal: [boolean, (v: boolean) => void];
  update: () => void;
};

export const ClearFormModal = ({
  clearFormModal,
  update,
}: ClearFormModalProps) => (
  <Modal
    title=" "
    subtitle="Clear Form?"
    subtitleIcon="information"
    open={clearFormModal}
    buttons={[
      {
        text: "Clear form",
        type: "submit",
      },
      {
        text: "Cancel",
        type: "reset",
        palette: "primary",
      },
    ]}
    closeOptions={{
      closeButton: false,
      escapeKey: false,
      clickOutside: false,
    }}
    onSubmit={update}
  >
    <p>
      This will remove any answers entered into the form and can not be undone.
    </p>
    <p>Are you sure you want to clear it?</p>
  </Modal>
);
