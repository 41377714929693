import styled from "@emotion/styled";
import { ReactNode } from "react";

import { Displayer } from "@smart/itops-editor-dom";

import { Group, GroupButtons, GroupHeading } from "./group-components";
import { GroupItem } from "../types";

const DescriptionWrapper = styled.div`
  p {
    margin-top: 0;
    font-size: ${(props) => props.theme.fontSize.base};
  }
`;

export type IntakeGroupProps = {
  group: GroupItem;
  index: number;
  onAdd?: () => void;
  onRemove?: () => void;
  children?: ReactNode;
};

export const IntakeGroup = ({
  group,
  index,
  onAdd,
  onRemove,
  children,
}: IntakeGroupProps) => (
  <Group>
    <GroupHeading group={group} index={index} />
    <DescriptionWrapper>
      <Displayer paragraphTag="p" value={group.description} />
    </DescriptionWrapper>
    {children}
    <GroupButtons group={group} onAdd={onAdd} onRemove={onRemove} />
  </Group>
);
