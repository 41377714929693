/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import styled from "@emotion/styled";
import { RefObject } from "react";

import { Displayer } from "@smart/itops-editor-dom";
import { Icon } from "@smart/itops-icons-dom";
import { specialChars } from "@smart/itops-utils-basic";

import { FieldError, GroupError } from "../../types";

type Error = { onClick?: () => void } & (FieldError | GroupError);

export const SingleError = ({
  name,
  label,
  index,
  error,
  fieldErrors,
  onClick,
}: Error) => (
  <li key={name} onClick={onClick} onKeyDown={() => {}}>
    <label htmlFor={name}>
      <Displayer
        paragraphTag="span"
        value={label}
        hintElement={
          index !== undefined && (
            <span>
              {specialChars.nbsp}
              {index + 1}
            </span>
          )
        }
      />
    </label>
    : {error}
    {!!fieldErrors && (
      <ul>
        {fieldErrors.map((err) => (
          <SingleError key={err.name} {...err} />
        ))}
      </ul>
    )}
  </li>
);

const ErrorSummaryWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.palette.danger.base};
  border-radius: 4px;
  color: ${(props) => props.theme.palette.danger.base};
  padding: ${(props) => props.theme.baseUnit * 0.4}rem
    ${(props) => props.theme.baseUnit * 0.8}rem;
  margin: ${(props) => props.theme.baseUnit * 1.6}rem 0
    ${(props) => props.theme.baseUnit}rem;

  p {
    display: flex;
    align-items: center;
    .exclamation {
      margin: 0 ${(props) => props.theme.baseUnit}rem;
    }

    font-size: ${(props) => props.theme.fontSize.emphasis};
    margin: ${(props) => props.theme.baseUnit}rem 0;
  }

  ul {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 400;
    padding-left: ${(props) => props.theme.baseUnit * 3.8}rem;

    li {
      margin: ${(props) => props.theme.baseUnit * 0.4}rem 0;

      label {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
`;

type ErrorSummaryProps = {
  errors: Error[];
  errorsRef?: RefObject<HTMLDivElement>;
};

export const ErrorSummary = ({ errors, errorsRef }: ErrorSummaryProps) => {
  if (!errors.length) return null;

  return (
    <ErrorSummaryWrapper data-testid="error-summary">
      <p ref={errorsRef} tabIndex={0}>
        <Icon
          className="exclamation"
          name="exclamationCircleFilled"
          size={18}
        />
        Please correct the following {errors.length} errors:
      </p>
      <ul>
        {errors.map((err) => (
          <SingleError key={err.name} {...err} />
        ))}
      </ul>
    </ErrorSummaryWrapper>
  );
};
