import styled from "@emotion/styled";
import { RefObject, useRef } from "react";

import { IconButton } from "@smart/itops-components-dom";
import { Displayer } from "@smart/itops-editor-dom";
import { isTestEnv } from "@smart/itops-utils-basic";

import { sidebarSpeed, sidebarWidth } from "./wrapper";
import {
  IntakeFormSectionRef,
  sectionFallback,
  SectionItem,
  summaryTitle,
} from "../../types";

const SectionSidebarWrapper = styled.nav`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: ${sidebarWidth}px;
  z-index: 90;

  background: var(--background);
  border-right: 1px solid ${(props) => props.theme.palette.background.highlight};

  transition: left ${sidebarSpeed} ease;

  display: flex;
  flex-flow: column;

  h3 {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: bold;
    margin: ${(props) => props.theme.baseUnit}rem 0;
    text-align: center;
  }

  .section-list {
    flex: 1;
    overflow: hidden auto;
    padding: 0 ${(props) => props.theme.baseUnit * 2}rem
      ${(props) => props.theme.baseUnit}rem;

    .section-nav {
      background: var(--background);
      border: 0;
      border-bottom: 1px solid
        ${(props) => props.theme.palette.background.highlight};
      cursor: pointer;
      outline: none;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      margin-bottom: ${(props) => props.theme.baseUnit * 0.2}rem;
      padding: ${(props) => props.theme.baseUnit}rem;
      display: block;
      text-align: left;
      width: 100%;

      font-size: ${(props) => props.theme.fontSize.base};

      &:hover,
      &:focus {
        background: ${(props) => props.theme.palette.background.accent};
      }

      &[aria-current="true"] {
        background: ${(props) => props.theme.palette.secondary.highlight};
      }

      &:disabled {
        background: var(--background);
        color: ${(props) => props.theme.palette.disabled.base};
        cursor: not-allowed;
      }
    }
  }

  .sidebar-toggle {
    position: absolute;
    bottom: ${(props) => props.theme.baseUnit * 2}rem;
    right: ${(props) => props.theme.baseUnit * -2.5}rem;

    margin: ${(props) => props.theme.baseUnit}rem;
    padding: ${(props) => props.theme.baseUnit * 0.8}rem
      ${(props) => props.theme.baseUnit * 0.7}rem
      ${(props) => props.theme.baseUnit * 0.8}rem
      ${(props) => props.theme.baseUnit * 0.9}rem;
    background: ${(props) => props.theme.palette.disabled.base};
    border: 1px solid ${(props) => props.theme.palette.foreground.accent};

    transform: rotate(180deg);
    transition: transform ${sidebarSpeed} ease;

    &:hover,
    &:focus {
      background: ${(props) => props.theme.palette.disabled.base};
    }
  }

  &[aria-disabled="true"] {
    left: -284px;

    .section-list {
      overflow: hidden hidden;
    }

    .sidebar-toggle {
      transform: rotate(0deg);
    }
  }
`;

const SectionSidebarBackground = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 89;

  background: ${(props) => props.theme.palette.foreground.base};
  opacity: 0.4;
  border: 0;
  padding: 0;
  transition: opacity, width;
  transition-duration: ${sidebarSpeed};
  transition-timing-function: ease, steps(1, jump-start);

  &[aria-disabled="true"] {
    width: 0;
    opacity: 0;

    transition-timing-function: ease, steps(1, jump-end);
  }

  ${(props) =>
    isTestEnv()
      ? ""
      : `
  @container (min-width: ${props.theme.breakPoints.readable + sidebarWidth}px) {
    display: none;
  }
  `}
`;

export type SectionSidebarProps = {
  visibleSections: SectionItem[];
  selected: SectionItem | undefined;
  onSelect: (section: SectionItem) => void;
  summary: boolean;
  onSummary: () => void;
  sidebar: boolean;
  setSidebar: (sidebar: boolean) => void;
  sectionRef?: RefObject<IntakeFormSectionRef>;
  isUpdating?: boolean;
  isAllVisited?: boolean;
  isNavigatable: (section: SectionItem) => boolean;
  isSectionVisiting: (section: SectionItem) => boolean;
};

export const SectionSidebar = ({
  visibleSections,
  selected,
  onSelect,
  summary,
  onSummary,
  sidebar,
  setSidebar,
  sectionRef,
  isUpdating,
  isAllVisited,
  isNavigatable,
  isSectionVisiting,
}: SectionSidebarProps) => {
  const backgroundRef = useRef<HTMLButtonElement>(null);
  const backgroundIsVisible = () =>
    backgroundRef.current &&
    window.getComputedStyle(backgroundRef.current).display === "block";
  const selectedIndex = selected
    ? visibleSections.findIndex((s) => s.uri === selected?.uri)
    : -1;

  const submitVisitingSection = (options?: { skip?: boolean }): boolean => {
    if (options?.skip) return true;

    if (sectionRef && selected && isSectionVisiting(selected))
      sectionRef.current?.submitForm();

    return (
      !sectionRef?.current?.errors &&
      sectionRef?.current?.connection?.status !== "error"
    );
  };

  return (
    <>
      <SectionSidebarBackground
        type="button"
        ref={backgroundRef}
        aria-disabled={!sidebar}
        onClick={() => setSidebar(false)}
      />
      <SectionSidebarWrapper aria-disabled={!sidebar}>
        <IconButton
          className="sidebar-toggle"
          name="angleRight"
          palette="foreground"
          title={sidebar ? "Close Menu" : "Open Menu"}
          size={12}
          onClick={() => setSidebar(!sidebar)}
        />
        <h3>Overview</h3>
        <div className="section-list">
          {visibleSections.map((section, index) => (
            <button
              key={section.uri}
              type="button"
              role="link"
              className="section-nav"
              onClick={() => {
                if (backgroundIsVisible()) setSidebar(false);

                if (
                  (summary || section.uri !== selected?.uri) &&
                  submitVisitingSection({
                    skip: !isUpdating && index < selectedIndex,
                  })
                )
                  onSelect(section);
              }}
              aria-current={!summary && index === selectedIndex}
              disabled={!summary && !isNavigatable(visibleSections[index])}
            >
              <Displayer
                value={section.title}
                paragraphTag="span"
                fallback={sectionFallback}
              />
            </button>
          ))}
          <button
            type="button"
            role="link"
            className="section-nav"
            onClick={() => {
              if (backgroundIsVisible()) setSidebar(false);
              if (!summary && submitVisitingSection()) onSummary();
            }}
            aria-current={!!summary}
            disabled={!isAllVisited}
          >
            {summaryTitle}
          </button>
        </div>
      </SectionSidebarWrapper>
    </>
  );
};
