import styled from "@emotion/styled";

import {
  defaultTemplate,
  templateResponsePlaceholders,
} from "@smart/bridge-templates-basic";
import { ContactDisplay, Displayer } from "@smart/itops-ui-dom";

import { ClearFormModal } from "./clear-form";
import { IntakePrintProps, useIntakePrint } from "./print-components";
import { Page, Section, SectionTeam, PageWrapper } from "./section-components";
import { SubmissionItem, TeamItem } from "../types";

const ClearFormButton = styled.div`
  margin: -6rem auto 1rem;
  max-width: 94%;
  width: ${(props) => props.theme.breakPoints.readable}px;
  display: flex;
  justify-content: flex-end;

  .edit-button {
    border: none;
    background: none;
    text-decoration: underline;
    color: ${(props) => props.theme.palette.primary.base};
    cursor: pointer;
  }
`;

export type IntakeStatusProps = {
  team: TeamItem;
  hideTeam?: boolean;
  response?: string;
  category?: string;
  status?: SubmissionItem["status"];
  deleted?: boolean | null;
  previewOptions?: {
    clearPreviewResponses: () => void;
    clearFormModal: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  };
  printOptions?: IntakePrintProps;
};

export const IntakeStatus = ({
  team,
  hideTeam,
  response,
  category,
  status,
  deleted,
  previewOptions,
  printOptions,
}: IntakeStatusProps) => {
  const { onPrint, elements } = useIntakePrint(printOptions);
  let statusText = "has been completed";
  if (status === "cancelled") statusText = "has been closed";
  if (status === "draft") statusText = "is not currently available";
  if (deleted) statusText = "has been removed";

  return (
    <PageWrapper sidebar={false}>
      <Page>
        {!hideTeam && <SectionTeam team={team} />}
        <Section data-testid="status-content">
          {!deleted && status === "completed" && (
            <Displayer
              paragraphTag="p"
              value={response}
              fallback={defaultTemplate.response}
              placeholders={{
                keys: templateResponsePlaceholders,
                data: {
                  downloadFormLink: "#download",
                  firmName: team.name,
                  firmPhone: team.phone || "",
                  matterCategory: category || "matter",
                },
              }}
              onPlaceholderClick={(e, element) => {
                if (element.name === "downloadFormLink") {
                  e.preventDefault();
                  onPrint();
                }
              }}
            />
          )}
          {(deleted || status !== "completed") && (
            <p>
              This form {statusText}. Please{" "}
              <ContactDisplay
                link={(team.email && `mailto:${team.email}`) || undefined}
                text={team.name || "us"}
              />{" "}
              if you have any further questions.
            </p>
          )}
        </Section>
      </Page>
      {!!previewOptions && (
        <>
          <ClearFormButton>
            <button
              className="edit-button"
              type="button"
              onClick={() => previewOptions.clearFormModal[1](true)}
            >
              Clear form
            </button>
          </ClearFormButton>
          <ClearFormModal
            clearFormModal={previewOptions.clearFormModal}
            update={previewOptions.clearPreviewResponses}
          />
        </>
      )}
      {elements}
    </PageWrapper>
  );
};
