import styled from "@emotion/styled";
import { MouseEvent } from "react";

import { Button } from "@smart/itops-smokeball-components-dom";
import { FailureMessage } from "@smart/itops-ui-dom";

import { TeamItem } from "../../types";

const SectionButtonsWrapper = styled.div`
  margin-top: ${(props) => props.theme.baseUnit * 3}rem;
  display: flex;
  justify-content: space-between;

  .buttons {
    display: flex;
    flex-flow: row wrap;
    gap: ${(props) => props.theme.baseUnit}rem;
  }

  .edit-button {
    border: none;
    background: none;
    text-decoration: underline;
    color: ${(props) => props.theme.palette.primary.base};
    cursor: pointer;
  }
`;

export type SectionButtonsProps = {
  onPrevious?: (e: MouseEvent<HTMLButtonElement>) => void;
  onNext?: (e: MouseEvent<HTMLButtonElement>) => void;
  onSubmit?: (e: MouseEvent<HTMLButtonElement>) => void;
  hasError?: string;
  isForm: boolean;
  team: TeamItem;
  clearFormModal?: () => void;
  displayClearFormButton: boolean;
  disabled?: { submit?: boolean };
};

export const SectionButtons = ({
  onPrevious,
  onNext,
  onSubmit,
  hasError,
  team,
  isForm,
  clearFormModal,
  displayClearFormButton,
  disabled,
}: SectionButtonsProps) => (
  <SectionButtonsWrapper>
    {hasError && (
      <FailureMessage
        action="submit form"
        contactName={team.name || "us"}
        contactLink={(team.email && `mailto:${team.email}`) || undefined}
      />
    )}
    <div className="buttons">
      {onPrevious && <Button text="Back" stretch={3} onClick={onPrevious} />}
      {onNext && (
        <Button
          text="Next"
          stretch={3}
          type={isForm ? "submit" : "button"}
          name="submit"
          value="next"
          onClick={onNext}
        />
      )}
      {onSubmit && (
        <Button
          text="Submit"
          palette="primary"
          stretch={3}
          type={isForm ? "submit" : "button"}
          name="submit"
          value="submit"
          onClick={onSubmit}
          disabled={disabled?.submit}
        />
      )}
    </div>
    {displayClearFormButton && (
      <button className="edit-button" type="button" onClick={clearFormModal}>
        Clear form
      </button>
    )}
  </SectionButtonsWrapper>
);
