import styled from "@emotion/styled";
import { MutableRefObject, ReactNode } from "react";

import { inputStyle } from "./input";
import { fieldName } from "../../hooks";

export const optionFallback = (index: number) => `Option ${index + 1}`;

export const optionsWithFallback = (
  options: { label: string; value: string }[] | null | undefined,
) =>
  (options?.length ? options : [{ label: "", value: "" }]).map(
    (option, idx) => ({
      value: option.value || optionFallback(idx),
      label: option.label || optionFallback(idx),
    }),
  );

const InputItemWrapper = styled.label<{ checked: boolean }>`
  cursor: pointer;
  display: flex;
  flex-flow: row;
  align-items: center;

  gap: 1rem;
  margin: 0 2rem 0.6rem 0;

  input[type="checkbox"],
  input[type="radio"] {
    appearance: none;
    outline: none;
    cursor: pointer;
    background: ${(props) =>
      props.checked
        ? props.theme.palette.primary.base
        : props.theme.palette.background.base};
    border: 4px solid ${(props) => props.theme.palette.background.base};
    box-shadow: 0 0 0 1px ${(props) => props.theme.palette.foreground.highlight};
    height: 1.8rem;
    width: 1.8rem;

    &:hover,
    &:focus {
      box-shadow:
        0 0 0 1px ${(props) => props.theme.palette.foreground.highlight},
        0 0 0 4px rgba(0, 0, 0, 0.1);
    }
  }

  input[type="checkbox"] {
    border-radius: 2px;
  }

  input[type="radio"] {
    border-radius: 1.8rem;
    margin: 0;
    flex: 0 0 1.8rem;
  }

  .label {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 300;
    margin: 0.4rem 0;
  }
`;

export const InputItemOther = styled.input`
  ${inputStyle}
  margin: 0;
  padding: 0.6rem 1rem;
  opacity: 1;
  transition: opacity 0.2s ease;
  max-width: ${(props) => props.theme.breakPoints.mobile}px;

  &[aria-hidden="true"] {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
`;

export const InputItem = ({
  innerRef,
  uri,
  index,
  type,
  label,
  value,
  checked,
  children,
  onChange,
  onBlur,
}: {
  innerRef?: MutableRefObject<HTMLInputElement | null>;
  uri: string;
  index?: number;
  type: "radio" | "checkbox";
  label: string;
  value: string;
  checked: boolean;
  children?: ReactNode;
  onChange: (value: string | undefined | null, checked: boolean) => void;
  onBlur?: () => void;
}) => (
  <InputItemWrapper checked={checked}>
    <input
      ref={innerRef}
      id={fieldName({ field: { uri }, index, suffix: value })}
      name={fieldName({ field: { uri }, index })}
      type={type}
      value={value}
      checked={checked}
      onChange={(e) => {
        onChange(e.currentTarget.value, checked);
      }}
      onClick={(e) => {
        if (type === "radio" && checked) {
          e.preventDefault();
          onChange(null, checked);
        }
      }}
      onBlur={onBlur}
    />
    <span className="label">{label}</span>
    {children}
  </InputItemWrapper>
);
