import styled from "@emotion/styled";
import Currency from "react-currency-input-field";

import { loadLocale } from "@smart/itops-locale-dom";

import { ErrorDisplay } from "./error";
import { inputStyle, inputWidth } from "./input";
import { Label } from "./label";
import { FieldProps, FieldWrapper } from "./wrapper";
import { fieldName } from "../../hooks";

const CurrencyInput = styled(Currency)`
  ${inputStyle}
  ${inputWidth}
`;

export const CurrencyField = ({
  field,
  index,
  innerRef,
  value,
  error,
  loading,
  disabled,
  onChange,
  onBlur,
}: FieldProps<HTMLInputElement>) => {
  const locale = loadLocale();
  const id = fieldName({ field, index });

  return (
    <FieldWrapper aria-disabled={disabled} isLoading={loading}>
      <Label {...field} index={index} />
      <CurrencyInput
        intlConfig={locale}
        ref={innerRef}
        id={id}
        name={id}
        aria-invalid={!!error}
        aria-errormessage={
          error ? fieldName({ field, index, suffix: "error" }) : undefined
        }
        value={value?.value ?? ""}
        onValueChange={(_, name, values) => {
          onChange(values && values.float ? values : null);
        }}
        onBlur={onBlur}
      />
      <ErrorDisplay uri={field.uri} index={index} error={error} />
    </FieldWrapper>
  );
};
