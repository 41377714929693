import styled from "@emotion/styled";
import { transparentize } from "polished";

import { Displayer } from "@smart/itops-editor-dom";

import { GroupItem } from "../../types";

const GroupButtonsWrapper = styled.div`
  margin: ${(props) => props.theme.baseUnit}rem 0;
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;

  button {
    background: none;
    border: 0;
    padding: ${(props) => props.theme.baseUnit * 1}rem
      ${(props) => props.theme.baseUnit * 2}rem;
    border-radius: ${(props) => props.theme.baseUnit * 2}rem;
    cursor: pointer;
    color: ${(props) => props.theme.palette.primary.base};
    font-size: ${(props) => props.theme.fontSize.base};
    text-decoration: underline;

    outline: none;
    &:focus {
      background: blue;
      background-color: ${(props) =>
        transparentize(0.9, props.theme.palette.foreground.base)};
    }
  }
`;

export type GroupButtonsProps = {
  group: GroupItem;
  onAdd?: () => void;
  onRemove?: () => void;
};

export const GroupButtons = ({
  group: { repeatPrompt },
  onAdd,
  onRemove,
}: GroupButtonsProps) =>
  onAdd || onRemove ? (
    <GroupButtonsWrapper>
      {onAdd && (
        <button type="button" onClick={onAdd}>
          <Displayer
            paragraphTag="span"
            value={`Add ${repeatPrompt?.trim() || ""}`.trim()}
          />
        </button>
      )}
      {onRemove && (
        <button type="button" onClick={onRemove}>
          <Displayer
            paragraphTag="span"
            value={`Remove ${repeatPrompt?.trim() || ""}`.trim()}
          />
        </button>
      )}
    </GroupButtonsWrapper>
  ) : null;
