import styled from "@emotion/styled";
import { MouseEvent } from "react";

import { checkHasResponse } from "@smart/bridge-types-basic";
import { Loading } from "@smart/itops-components-dom";

import { ClearFormModal } from "./clear-form";
import {
  Page,
  Section,
  SectionButtons,
  SectionTeam,
  PageWrapper,
  SectionHeadingWrapper,
  SectionForm,
} from "./section-components";
import { ErrorSummary } from "./shared-components/error-summary";
import {
  SummaryField,
  SummaryGroup,
  SummarySection,
} from "./summary-components";
import { renderOrderedItems, requiredMessage } from "../hooks";
import {
  ConnectionItem,
  OrderedSection,
  SectionItem,
  summaryTitle,
  TeamItem,
} from "../types";

const SummaryPage = styled(Page)`
  position: relative;
`;

export type IntakeSummaryProps = {
  team: TeamItem;
  hideTeam?: boolean;
  connection?: ConnectionItem;
  visibleSections: OrderedSection[];
  responses: Record<string, any>;
  isLoadingDBResponses: boolean;
  sidebar: boolean;
  onSelect?: (section: SectionItem) => void;
  onSubmitButton?: (e: MouseEvent<HTMLButtonElement>) => void;
  onSubmit?: () => void;
  onReset?: () => void;
  submitError?: string;
  isForm?: boolean;
  clearFormModal?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  clearPreviewResponses?: () => void;
  hidePreviewErrors?: boolean;
};

const noop = () => {};

export const IntakeSummary = ({
  team,
  hideTeam,
  connection,
  visibleSections,
  responses,
  isLoadingDBResponses,
  sidebar,
  onSelect,
  onSubmitButton,
  onSubmit,
  onReset,
  submitError,
  isForm = true,
  clearFormModal,
  clearPreviewResponses,
  hidePreviewErrors,
}: IntakeSummaryProps) => {
  const missingRequired = visibleSections
    .map(({ orderedItems }) =>
      orderedItems.map((item) =>
        item.type === "group" ? item.fields : [item.field],
      ),
    )
    .flat(2)
    .filter(
      (field) =>
        field.mandatory &&
        !checkHasResponse(
          field.type,
          field.groupUri ? responses[field.uri]?.[0] : responses[field.uri],
        ),
    );

  return (
    <PageWrapper sidebar={sidebar}>
      <SummaryPage>
        {isLoadingDBResponses && <Loading />}
        {!hideTeam && <SectionTeam team={team} connection={connection} />}
        <Section>
          <SectionForm onSubmit={onSubmit} onReset={onReset} isForm={isForm}>
            <SectionHeadingWrapper>
              <h1>{summaryTitle}</h1>
              <p>Please review your responses</p>
            </SectionHeadingWrapper>
            {!hidePreviewErrors && !!missingRequired.length && (
              <ErrorSummary
                errors={missingRequired.map((field) => ({
                  name: field.uri,
                  label: field.label,
                  error: requiredMessage,
                  onClick: () => {
                    const section = visibleSections.find(
                      (s) => s.section.uri === field.sectionUri,
                    );
                    if (section) {
                      onSelect?.({
                        ...section.section,
                        validateOnDisplay: true,
                      });
                    }
                  },
                }))}
              />
            )}
            {visibleSections.map(({ orderedItems, section }) => (
              <SummarySection
                key={section.uri}
                section={section}
                onEdit={onSelect ? () => onSelect(section) : undefined}
              >
                {renderOrderedItems({
                  orderedItems,
                  responses,
                  renderGroup: (props) => <SummaryGroup {...props} />,
                  renderField: (props) => <SummaryField {...props} />,
                })}
              </SummarySection>
            ))}
            <SectionButtons
              isForm={isForm}
              team={team}
              hasError={submitError}
              onPrevious={
                onSelect
                  ? () =>
                      onSelect(
                        visibleSections[visibleSections.length - 1].section,
                      )
                  : undefined
              }
              onSubmit={onSubmitButton || noop}
              clearFormModal={() => clearFormModal?.[1](true)}
              displayClearFormButton={!!clearFormModal}
              disabled={{
                submit: !hidePreviewErrors && !!missingRequired.length,
              }}
            />
          </SectionForm>
        </Section>
      </SummaryPage>
      {clearFormModal && clearPreviewResponses && (
        <ClearFormModal
          clearFormModal={clearFormModal}
          update={clearPreviewResponses}
        />
      )}
    </PageWrapper>
  );
};
