import { Helmet } from "react-helmet-async";

import {
  IntakeForm,
  LoadResponses,
  useAnalytics,
} from "@smart/bridge-intake-components-dom";
import { track } from "@smart/bridge-metrics-dom";
import { StaffDetails } from "@smart/bridge-types-basic/src/appointment";

import {
  intakeApi,
  IntakeApiForm,
  IntakeApiSubmission,
  IntakeApiTeam,
  useAddressLookup,
  useFileLookup,
} from "../../hooks";

export type SubmissionFormProps = {
  team: IntakeApiTeam;
  form: IntakeApiForm;
  submission: IntakeApiSubmission;
  hideTeam?: boolean;
  loadResponses: LoadResponses;
  staffDetails: StaffDetails[];
};

export const SubmissionForm = ({
  team,
  form,
  submission,
  hideTeam,
  loadResponses,
  staffDetails,
}: SubmissionFormProps) => {
  const uris = {
    teamUri: team.uri,
    submissionUri: submission.uri,
    formUri: form.uri,
  };
  const lookup = {
    addressLookup: useAddressLookup(),
    fileLookup: useFileLookup(uris),
  };
  const { post, result, ...connection } = intakeApi.submit();
  const { sendEvent } = useAnalytics();

  return (
    <>
      <Helmet>
        <title>{[team.name, form.title].filter(Boolean).join(" | ")}</title>
      </Helmet>
      <IntakeForm
        connection={connection}
        form={{
          team,
          hideTeam,
          response: form.response,
          category: form.category,
        }}
        sections={form.sections}
        groups={form.groups}
        fields={form.fields}
        lookup={lookup}
        submission={submission}
        submitResponses={(input) => {
          if (input.status === "completed") {
            track("Submission Completed", {
              formUri: uris.formUri,
              submissionUri: uris.submissionUri,
            });
            sendEvent({
              name: "form_submission",
              params: {
                form_title: form.title,
              },
            });
          }

          return post({ body: { ...uris, ...input } });
        }}
        loadResponses={loadResponses}
        shouldLoadDBResponses
        postSubmissionStatus={result?.submissionStatus}
        staffDetails={staffDetails}
      />
    </>
  );
};
