import styled from "@emotion/styled";

import { EditableInput } from "@smart/itops-ui-dom/src/components/editable-text/editable-input";

import { Hint } from "./hint";
import { Indicator } from "./indicator";
import { fieldName } from "../../hooks";
import { FieldComponentType, fieldFallbackLabel, FieldItem } from "../../types";

export type LabelProps = Pick<
  FieldItem,
  "uri" | "label" | "hint" | "description" | "mandatory" | "type" | "layout"
> & { index?: number };

const Question = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = ({
  uri,
  label,
  hint,
  description,
  mandatory,
  index,
  type,
  layout,
}: LabelProps) => {
  const fallback = layout
    ? fieldFallbackLabel.mapped
    : fieldFallbackLabel[type as FieldComponentType];

  return (
    <label htmlFor={fieldName({ field: { uri }, index })}>
      <Question>
        <EditableInput
          text={label || fallback}
          fontSize="subHeading"
          isPreview
          disabled
        />
        {mandatory && <Indicator />}
        {hint && <Hint content={hint} />}
      </Question>
      {description && (
        <EditableInput
          text={description}
          fontSize="base"
          dataTestId="description"
          isPreview
          disabled
        />
      )}
    </label>
  );
};
