import styled from "@emotion/styled";
import { useMergeRefs } from "@floating-ui/react";
import { useLayoutEffect, useRef } from "react";

import { ErrorDisplay } from "./error";
import { inputStyle } from "./input";
import { Label } from "./label";
import { FieldProps, FieldWrapper } from "./wrapper";
import { fieldName } from "../../hooks";

const TextAreaInput = styled.textarea`
  ${inputStyle}

  padding: ${(props) => props.theme.baseUnit * 0.7}rem 1rem;
  line-height: 1.6;
  resize: none;
`;

export const MultilineField = ({
  field,
  index,
  innerRef,
  value,
  error,
  loading,
  disabled,
  onChange,
  onBlur,
}: FieldProps<HTMLTextAreaElement>) => {
  const fieldRef = useRef<HTMLTextAreaElement>(null);
  useLayoutEffect(() => {
    if (fieldRef?.current) {
      fieldRef.current.style.height = "0";
      fieldRef.current.style.height = `${fieldRef.current.scrollHeight + 4}px`;
    }
  }, [value]);
  const id = fieldName({ field, index });
  const errorId = fieldName({ field, index, suffix: "error" });
  const mergedRef = useMergeRefs([innerRef, fieldRef]);

  return (
    <FieldWrapper aria-disabled={disabled} isLoading={loading}>
      <Label {...field} index={index} />
      <TextAreaInput
        ref={mergedRef}
        id={id}
        name={id}
        aria-invalid={!!error}
        aria-errormessage={error ? errorId : undefined}
        value={value ?? ""}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        rows={1}
      />
      <ErrorDisplay uri={field.uri} index={index} error={error} />
    </FieldWrapper>
  );
};
